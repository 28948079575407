import handleErr from './handle-error'

const calculateHeading = ({ heading, lat1, lon1, lat2, lon2 }) => {
  try {
    if (heading) return heading
    const y = Math.sin(lon2 - lon1) * Math.cos(lat2)
    const x =
      Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1)
    const bearing = (Math.atan2(y, x) * 180) / Math.PI
    return (bearing + 360) % 360 // Normalize to 0-360 degrees
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

const headingDirection = ({ heading }) => {
  try {
    if (heading !== 0 && (heading >= 337.5 || heading < 22.5)) {
      return 'North'
    } else if (heading >= 22.5 && heading < 67.5) {
      return 'Northeast'
    } else if (heading >= 67.5 && heading < 112.5) {
      return 'East'
    } else if (heading >= 112.5 && heading < 157.5) {
      return 'Southeast'
    } else if (heading >= 157.5 && heading < 202.5) {
      return 'South'
    } else if (heading >= 202.5 && heading < 247.5) {
      return 'Southwest'
    } else if (heading >= 247.5 && heading < 292.5) {
      return 'West'
    } else {
      return 'East'
    }
  } catch (err) {
    handleErr({ err })
    return 'East'
  }
}

export { calculateHeading, headingDirection }
