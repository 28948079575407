import handleErr from './handle-error'
import isEmpty from './is-empty'

const mileToMeters = (miles) => {
  try {
    return !isEmpty(miles) ? Number((+miles * 1609.344).toFixed(2)) : 0
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

export { mileToMeters }
