import handleErr from './handle-error'
import { getTripDestination, isEnded } from './helpers'
import isEmpty from './is-empty'

// const destIdx = ({ destLen, progress }) => Math.floor((destLen * progress) / (destLen * 3))
const idxToGo = ({ progress }) => Math.floor(progress / 2) - Math.floor(progress / 4)
const destIdxToShow = ({ progress }) => Math.floor(progress / 4)

const tripInfo = ({ trip, forcedProgress, tripsDest }) => {
  try {
    if (!trip) return {}
    const destLen = trip?.destinations?.length
    const progress = forcedProgress || trip?.progress || 0
    const lastProgress = destLen === 1 ? 4 : destLen * 4 - 4
    const destinationIdxToShow = destIdxToShow({ progress })
    const destinationIdxToGo = destLen === 1 ? 0 : idxToGo({ progress })
    const destPending = destLen - destinationIdxToGo
    const stepVal = progress / 4 - Math.floor(progress / 4)
    const isStart = stepVal === 0 && progress < lastProgress // pending start
    const isArrive = stepVal === 0.25 // pending arrive
    const isPickup = stepVal === 0.5 // pending pickup
    const isDropoff = stepVal === 0.75 // pending dropoff
    const timeIdx = isStart || isArrive ? destinationIdxToGo + 1 : destinationIdxToGo || 1
    const isDroppedOff = progress >= lastProgress
    // console.log('++++++++++++++++++++++++')
    // console.log('timeIdx', timeIdx)
    // console.log('idxToGo', idxToGo({ progress }))
    // console.log('destinationIdxToShow', destinationIdxToShow)
    // console.log('stepVal', stepVal)
    // console.log('progress', progress)
    // console.log('lastProgress', lastProgress)
    // console.log('destPending', destPending)
    // console.log('isStart', isStart)
    // console.log('isArrive', isArrive)
    // console.log('isPickup', isPickup)
    // console.log('isDropoff', isDropoff)
    // console.log('isDroppedOff', isDroppedOff)
    // console.log('++++++++++++++++++++++++')
    return {
      ...trip,
      destLen,
      lastProgress,
      destPending,
      idxToGo: destinationIdxToGo,
      destIdxToShow: isEnded(trip)
        ? destinationIdxToShow - 1
        : destLen === 1
        ? 0
        : destinationIdxToShow,
      timeIdx,
      // timeIdx: Number(destinationIdxToShow + (progress % 4 === 0 ? 0 : 1)) || 1,
      // idxToGo: Math.floor(progress / 2) - Math.floor(progress / 4), // start 1
      // idxToGo: Math.floor(0 / 2) - Math.floor(0 / 4), // start 1
      destData:
        !isEmpty(tripsDest) && destLen
          ? trip.destinations?.map((dest) =>
              getTripDestination({
                tripsDest,
                tripDestId: dest?.id || dest?.value,
              }),
            )
          : {},
      stepVal,
      isStart,
      isArrive,
      isPickup,
      isDropoff,
      isDroppedOff,
      // isStart: progress / 4 - Math.floor(progress / 4) === 0,
      // isArrive: progress / 4 - Math.floor(progress / 4) === 0.25,
      // isPickup: progress / 4 - Math.floor(progress / 4) === 0.5,
      // isDropoff: progress / 4 - Math.floor(progress / 4) === 0.75,
    }
  } catch (err) {
    handleErr({ err })
    return trip
  }
}

export { tripInfo, idxToGo, destIdxToShow }
