import { tripInfo } from './get-trip-info'
import handleErr from './handle-error'
import { getTripsPendingByDriver } from './helpers'
import isEmpty from './is-empty'

const tripTrajectoryPickedUp = ({ trip, trajectoryId, isStart, isPickup, isArrive, isDropoff }) => {
  try {
    const { trajectory = [] } = trip
    // if (!isArrive) return trajectory
    const trajIdx = trajectory.findIndex((tr) => tr.trajectoryId === trajectoryId)
    if (trajIdx >= 0) {
      trajectory[trajIdx].isStart = isStart || trajectory[trajIdx].isStart
      trajectory[trajIdx].isPickup = isPickup || trajectory[trajIdx].isPickup
      trajectory[trajIdx].isArrive = isArrive || trajectory[trajIdx].isArrive
      trajectory[trajIdx].isDropoff = isDropoff || trajectory[trajIdx].isDropoff
    }

    return trajectory
  } catch (err) {
    handleErr({ err })
    return trip?.trajectory || []
  }
}

const sortTrajArr = ({ arr, leg }) => {
  try {
    console.log({ arr })
    return arr
      ?.filter((tr) => (leg ? leg === tr.stage : true))
      ?.filter((tr) => !tr?.isArrive && !tr?.isDropoff)
      .sort((a, b) => {
        console.log('a.queueIdx: ', a.queueIdx)
        console.log('b.queueIdx: ', b.queueIdx)
        return a.queueIdx - b.queueIdx
      })
  } catch (err) {
    handleErr({ err })
    return [0]
  }
}

const sortByTrajectoryQueueIdx = ({ a, b, leg, trajectoriesState }) => {
  try {
    if (Array.isArray(trajectoriesState) && trajectoriesState?.length) {
      return sortTrajArr({ arr: trajectoriesState, leg })
    }
    if (trajectoriesState && Object.keys(trajectoriesState)?.length) {
      // const sortedTrajState = {}
      const sortedTrajState = Object.keys(trajectoriesState).map((driverId) => ({
        ...trajectoriesState,
        [driverId]: trajectoriesState[driverId]?.length
          ? sortTrajArr({ arr: trajectoriesState[driverId], leg })
          : [],
      }))
      return sortedTrajState
    }

    const tripA = a?.t1Info || tripInfo({ trip: a })
    const tripB = b?.t1Info || tripInfo({ trip: b })

    const { trajectory: trajectoryA } = tripA
    const { trajectory: trajectoryB } = tripB

    // console.log('leg: ', leg)
    // console.log('tripA: ', tripA)
    // console.log('tripB: ', tripB)
    // console.log('trajectoryA: ', trajectoryA)
    // console.log('trajectoryB: ', trajectoryB)

    if (!trajectoryA?.length && !trajectoryB?.length) return 0
    if (!trajectoryA?.length) return 1
    if (!trajectoryB?.length) return -1

    const stageA = getTripTrajStages({ trip: a, leg })[0] //|| tripA?.timeIdx || 1
    const stageB = getTripTrajStages({ trip: b, leg })[0] //|| tripB?.timeIdx || 1

    console.log('stageA: ', stageA)
    console.log('stageB: ', stageB)

    const trajQueueIdxA = trajectoryA
      ?.filter((t) => (leg ? leg === t.stage : true))
      ?.filter((traj) => !traj.isArrive && !traj.isDropoff)
      ?.sort((a, b) => a.queueIdx - b.queueIdx)[0]?.queueIdx
    // ?.find((traj) => traj?.stage === (leg || stageA))?.queueIdx // find the first
    const trajQueueIdxB = trajectoryB
      ?.filter((t) => (leg ? leg === t.stage : true))
      ?.filter((traj) => !traj.isArrive && !traj.isDropoff)
      ?.sort((a, b) => a.queueIdx - b.queueIdx)[0]?.queueIdx
    // ?.find((traj) => traj?.stage === (leg || stageB))?.queueIdx // find the first
    if (!trajQueueIdxA && trajQueueIdxB) return 1
    if (!trajQueueIdxB && trajQueueIdxA) return -1

    console.log('trajQueueIdxA: ', trajQueueIdxA)
    console.log('trajQueueIdxB: ', trajQueueIdxB)
    return trajQueueIdxA > 0 && trajQueueIdxB > 0 ? trajQueueIdxA - trajQueueIdxB : 0
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

// similar to timeIdx or leg but iqualized to all trips
const getTripTrajStages = ({ trip, leg }) => {
  try {
    const { trajectory, timeIdx } = tripInfo({ trip }) || {}
    // const tripMaxTrajStage = Math.ceil(trajectory?.length / 2)
    // const tripTrajStages = trajectory?.map((traj) => traj?.stage)?.sort()
    // console.log(trajectory)
    // console.log(tripTrajStages)
    if (leg) return [leg]
    if (!trajectory?.length) return [timeIdx || 1]
    const filteredTraj = trajectory
      ?.filter((t) => !t?.isArrive && !t?.isDropoff && t?.stage >= timeIdx)
      ?.map((traj) => traj?.stage)
      ?.sort()
    return !filteredTraj?.length ? [timeIdx || 1] : filteredTraj
  } catch (err) {
    handleErr({ err })
    return [1]
  }
}

const getTrajCurrentStage = ({ trip, leg, trips }) => {
  try {
    // const { timeIdx: tripTimeIdx } = tripInfo({ trip })
    //: tripsDataRef.current
    if (leg) return leg
    // console.log(leg)
    // const selectedLeg = leg || 1 // getTripTrajStage({ trip })
    const driver = trip?.driver
    const smallerStage =
      getTripsPendingByDriver({ trips, driver })
        ?.map((trip) => getTripTrajStages({ trip }))
        ?.flat()
        ?.sort()[0] || 1
    // console.log(smallerStage)
    return smallerStage
  } catch (err) {
    handleErr({ err })
    return 1
  }
}

const findTripTrajectory = ({ trip, row, column, destId, leg, trips }) => {
  try {
    const stage = getTripTrajStages({ trip, leg })[0]
    // const stage = getTrajCurrentStage({ trip, leg, trips })
    // console.log('stage: ', stage, 'row: ', row, 'column ', column, 'destId ', destId)
    console.log('stage: ', stage)
    console.log('trip?._id: ', trip?._id)
    console.log('trip?.customer: ', trip?.customer)
    console.log('destId: ', destId)
    console.log('trip?.trajectory: ', trip?.trajectory)
    return trip?.trajectory?.find(
      (x) =>
        x?.stage === stage &&
        x?.tripId === trip?._id &&
        x?.customer === trip?.customer &&
        x?.destId === destId,
    )
    // return trip?.trajectory?.find(
    //   (x) => x?.stage === stage && x?.row === row && x?.column === column && x?.destId === destId,
    // )
    // return trip?.trajectory?.find((x) => x?.trajectoryId === `${stage}-${trip?._id}-${destId}`)
  } catch (err) {
    handleErr({ err })
    return ''
  }
}

const addGetTrajectories = ({ trip, trips, trajectoriesState, cb }) => {
  try {
    const driver = trip?.driver || 'nodriver'
    if (
      (isEmpty(trajectoriesState) || !trajectoriesState[driver]?.length) &&
      trip?.trajectory?.length
    ) {
      const updatedTraj = {
        ...(trajectoriesState || {}),
        [driver]: getTripsPendingByDriver({ trips, driver: trip?.driver })
          ?.map((t) => t?.trajectory)
          ?.flat(1),
      }
      cb && cb({ updatedTraj })
      return updatedTraj
    }
    return trajectoriesState
  } catch (err) {
    handleErr({ err })
    return trajectoriesState
  }
}

const getTrajectoryLastQueueIdx = ({ trip, trips, leg, trajectoriesState }) => {
  try {
    const foundtrajectories = addGetTrajectories({ trip, trips, trajectoriesState })
    // const { timeIdx: tripTimeIdx } = tripInfo({ trip })
    // const timeIdx = leg || tripTimeIdx || 1
    // const currentStage = getTrajCurrentStage({ trip, trips, leg })

    const driver = trip?.driver || 'nodriver'
    return (
      foundtrajectories[driver]
        // ?.filter((x) => +x?.stage === +currentStage)
        ?.map((x) => x?.queueIdx)
        ?.sort()
        ?.reverse()[0] || 0
    )
  } catch (err) {
    handleErr({ err })
  }
}

const getTripsTrajectories = ({ trips, driverId, leg, noArrived, noDroppedOff }) => {
  const allTripsByDriver = trips.filter((t) => t.driver === driverId) //.slice(0, 50)
  const tTrajByLeg = allTripsByDriver
    .map(
      (t) =>
        // t?.trajectory.filter(
        //   (tr) =>
        //     tr?.stage === (leg || getTrajCurrentStage({ trip: t, leg, trips: allTripsByDriver })),
        // ),
        t?.trajectory, //.filter((tr) => tr?.stage === (leg || getTripTrajStages({ trip: t, leg })[0])),
    )
    ?.flat()
    ?.filter((tr) => (noArrived ? !tr?.isArrive : true))
    ?.filter((tr) => (noDroppedOff ? !tr?.isDropoff : true))
  // .sort((a, b) => a.queueIdx - b.queueIdx)
  // ?.map((trajectoriesState) => sortByTrajectoryQueueIdx({ trajectoriesState, leg }))
  // console.log({ tTrajByLeg })

  return sortByTrajectoryQueueIdx({ trajectoriesState: tTrajByLeg, leg })
}

const insertTripInTrajectory = ({ trip, trips, leg, tripsDest, trajectoriesState, cb }) => {
  try {
    const driverId = trip?.driver || 'nodriver'
    const { _id, trajectory, destData = [] } = tripInfo({ trip, tripsDest })
    const tripStage = getTripTrajStages({ trip, leg })[0]
    const tripTrajByStage = trajectory.filter((t) => t.stage === tripStage)
    const tripsTrajectories = getTripsTrajectories({ trips, driverId })
    const tripIdx = trips.findIndex((t) => t._id === trip._id)
    const tripBefore = trips[tripIdx - 1]
    const { trajectory: tripBeforeTraj } = tripBefore
    const tripBeforeStage = getTripTrajStages({ trip: tripBefore })[0]
    const tripBeforeTrajByStage = tripBeforeTraj?.filter((tr) => tr.stage === tripBeforeStage)
    const tripBeforeTrajLastQueueIdx =
      tripBeforeTrajByStage
        ?.map((tr) => tr.queueIdx)
        ?.sort()
        ?.reverse()[0] || 0

    const updateTripTraj = [...(trajectory || [])]
    const queueIdxAdded = []

    const loopStart = (tripStage || 1) - 1 >= 0 ? (tripStage || 1) - 1 : 0
    const loopEnd = tripStage > 0 ? tripStage : 1
    for (let i = loopStart; i < loopEnd; i++) {
      const destId = trip?.destinations[i]?.id
      if (destId) {
        const trajectoryId = `${tripStage}-${_id}-${destId}`
        const nextQueueIdx = tripBeforeTrajLastQueueIdx + 1 + i
        queueIdxAdded.push(nextQueueIdx)
        const foundTraj =
          findTripTrajectory({
            trip,
            destId,
            leg: leg || tripStage,
          }) || {}
        if (isEmpty(foundTraj)) {
          foundTraj.name = destData[i]?.name
          foundTraj.tripType = trip?.tripType
          foundTraj.coordType = destData[i]?.level ? 'Customer' : 'Destination'
          foundTraj.tripId = _id
          foundTraj.customer = trip?.customer
          foundTraj.trajectoryId = trajectoryId
          foundTraj.queueIdx = nextQueueIdx
          foundTraj.stage = tripStage
          foundTraj.timeIdx = tripStage
          foundTraj.column = i < tripStage ? 1 : 2
          foundTraj.destId = destId
          foundTraj.location = destData[i]?.location
          updateTripTraj.push(foundTraj)
        }
        const foundIdx = updateTripTraj.findIndex((x) => x?.trajectoryId === trajectoryId)
        if (foundIdx) updateTripTraj[foundIdx].queueIdx = nextQueueIdx
      }
    }
    trip.trajectory = updateTripTraj

    const lastQueueIdxAdded = queueIdxAdded.sort().reverse()[0]
    const updatedTripsTraj = trips
      ?.filter((t) => t.driver === driverId)
      .map((t, i) => {
        if (i >= tripIdx) {
          t.trajectory = t?.trajectory?.map((tr) => {
            if (tr.queueIdx <= lastQueueIdxAdded) {
              tr.queueIdx = lastQueueIdxAdded + 1 + i
              return tr
            }
            return tr
          })
          return t
        }
        return t
      })

    const updatedTraj = {
      ...(trajectoriesState || {}),
      [driverId]: [...updatedTripsTraj, trip]?.map((t) => t?.trajectory)?.flat(1),
    }

    const updatedTrajSorted = sortByTrajectoryQueueIdx({ leg, trajectoriesState: updatedTraj })

    cb && cb({ updatedTraj: updatedTrajSorted, updatedTripsTraj })
    return { updatedTraj: updatedTrajSorted, updatedTripsTraj }
  } catch (err) {
    handleErr({ err })
    return { updatedTraj: trajectoriesState, updatedTripsTraj: trips }
  }
}

const removeTrajectories = ({ driver, trips, trajectoriesState, cb }) => {
  try {
    const remainingTrajectories = addGetTrajectories({
      trip: { driver: driver || 'nodriver' },
      trips,
      trajectoriesState,
    })
    delete remainingTrajectories[driver || 'nodriver']
    console.log(remainingTrajectories)
    cb && cb({ remainingTrajectories })
  } catch (err) {
    handleErr({ err })
  }
}

export {
  tripTrajectoryPickedUp,
  sortByTrajectoryQueueIdx,
  getTripTrajStages,
  findTripTrajectory,
  getTrajCurrentStage,
  addGetTrajectories,
  getTrajectoryLastQueueIdx,
  getTripsTrajectories,
  insertTripInTrajectory,
  removeTrajectories,
}
