import React from 'react'
import AppModal from './modal'

const toggleModal = ({ ...p }) => {
  const modalIsVisible = p.closeModal ? !p.closeModal : p.modalVisible
  // setModalId(p.modalId || '')
  // setModalVisible(modalIsVisible)
  // setModalTitle(p.modalTitle || '')
  // setModalBody(p.modalBody || '')
  // setModalType(p.modalType || '')
  // setModalIsOnTrip(p.modalIsOnTrip || '')
  // setModalDraggable(p.modalDraggable || false)
  // setModalResize(p.modalResize || false)

  // setShowModal(
  return modalIsVisible ? (
    <AppModal
      toggleModal={({ ...params }) =>
        p?.toggleModal && p?.setShowModal
          ? p?.setShowModal(p?.toggleModal({ ...params }))
          : p?.setShowModal(toggleModal({ ...params }))
      }
      modalId={p.modalId}
      modalTitle={p.modalTitle}
      noHeader={p.noHeader}
      modalVisible={p.modalVisible}
      modalBody={p.modalBody}
      customClassName={p.customClassName}
      modalBeforeBodyEnd={p.modalBeforeBodyEnd}
      footerContent={p.footerContent}
      modalType={p.modalType}
      modalIsOnTrip={p.modalIsOnTrip}
      userIsDriver={p.userIsDriver}
      modalDraggable={p.modalDraggable}
      modalResize={p.modalResize}
      isOnLine={p.onLineState}
      cb={p.cb}
      // saveModal={saveModal}
    />
  ) : null
  // )
}
export default toggleModal
