import handleErr from './handle-error'
import isEmpty from './is-empty'

const metersToMiles = (meters) => {
  try {
    return !isEmpty(meters) ? Number((+meters * 0.000621371192).toFixed(2)) : 0
  } catch (err) {
    handleErr({ err })
    return 0
  }
}

export { metersToMiles }
